import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import VueWorker from 'vue-worker'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { library } from '@fortawesome/fontawesome-svg-core'
//import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle, faCheckCircle, faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
// import { fafontbrands } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueMoment from 'vue-moment'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
const moment = require('moment')
require('moment/locale/fr');
var VueScrollTo = require('vue-scrollto');

Vue.use(VueResource)
Vue.use(VueRouter)
Vue.use(VueWorker)
Vue.use(VueMoment, { moment })
Vue.use(VueReCaptcha, { siteKey: '6LfhjMoZAAAAAOCeWlx6R8esVtrjpyuNJh_rjUZG' })

library.add(faCircle, faCheckCircle, faSquare, faCheckSquare)
Vue.component('fa-icon', FontAwesomeIcon)

Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})

const router = new VueRouter({
    mode: 'hash',
    routes: [{
        path: '/',
        component: require('./components/AccountLogin').default
    }, {
        path: '/account/login',
        component: require('./components/AccountLogin').default
    }, {
        path: '/account/create',
        component: require('./components/AccountCreate').default
    }, {
        path: '/account',
        component: require('./components/page_account').default
    }, {
        path: '/board',
        component: require('./components/page_board').default
    }, {
        path: '/reservation',
        component: require('./components/page_reservation').default
    }, {
        path: '/invoices',
        component: require('./components/page_invoices').default
    }, {
        path: '/planning',
        component: require('./components/page_planning').default
    }, {
        path: '*',
        redirect: '/'
    }]
})

Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')


Vue.filter('money', function(value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});