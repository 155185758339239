import Vue from 'vue';
var CryptoJS = require("crypto-js");

class DataAPI {
    constructor() {
        // this.URL = "https://rdt13.accen.me/api";
        this.URL = "https://reservation.leveloplus.com/api";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    Guid() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    }

    IsProduction() {
        return true;
    }

    Account_Login(login, pwd, callback) {
        var datas = { login: login, pwd: pwd };
        Vue.http.post(this.URL + '/v1/account/login', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Logout(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/account/logout', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Email_Exist(login, callback) {
        var datas = { login: login };
        Vue.http.post(this.URL + '/v1/account/exist', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Info(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/account/info', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Edit(data, callback) {
        var datas = { data: data };
        Vue.http.post(this.URL + '/v1/account/edit', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Edit_Bank(data, callback) {
        var datas = { data: data };
        Vue.http.post(this.URL + '/v1/account/edit/bank', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Create(data, callback) {
        var datas = { data: data };
        Vue.http.post(this.URL + '/v1/account/create', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Create_Valid(apikey, callback) {
        var datas = { apikey: apikey };
        Vue.http.post(this.URL + '/v1/account/create/valid', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Recovery_Ask(email, callback) {
        var datas = { email: email };
        Vue.http.post(this.URL + '/v1/account/recovery/ask', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Recovery_Valid(apikey, pwd, callback) {
        var datas = { apikey: apikey, pwd: pwd };
        Vue.http.post(this.URL + '/v1/account/recovery/valid', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Inactive(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/account/inactive', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Delete(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/account/delete', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Agency_List(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/agency/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Material_List(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/material/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Rentals_List(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/rentals/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Invoices_List(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/invoices/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    invoices_exist(callback) {
        console.log("invoices_exist");
        var datas = {};
        Vue.http.post(this.URL + '/v1/invoice/exist', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    File_Exist(doctype, callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/file/exist/' + doctype, this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    File_Download(doctype, callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/file/download/' + doctype, this.encrypt(datas), {
            responseType: "arraybuffer",
            credentials: false
        }).then(res => { callback(res); }, reserror => { callback(reserror); });
    }


    File_Download_invoice(number, callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/file/download/invoice/' + number, this.encrypt(datas), {
            responseType: "arraybuffer",
            credentials: false
        }).then(res => { callback(res); }, reserror => { callback(reserror); });
    }

    File_Invoice_Download(code, file, callback) {
        var datas = { code, file };
        Vue.http.post(this.URL + '/v1/file/download_invoice', this.encrypt(datas), {
            responseType: "json",
            credentials: false,
            headers: {}
        }).then(res => {
            console.log("res", this.decrypt(res.bodyText));
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    File_Upload(file, doctype, callback) {
        var formData = new FormData();
        formData.append('file', file);
        Vue.http.post(this.URL + '/v1/file/upload/' + doctype, formData, {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    GetHmac(paquet, callback) {
        var datas = { paquet: paquet };
        Vue.http.post(this.URL + '/v1/hmac', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Recaptcha_Send(recaptcha_user, callback) {
        var datas = { recaptcha_user: recaptcha_user };
        Vue.http.post(this.URL + '/v1/recaptcha', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.bodyText)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Reservation_Create(vdata, callback) {
        var datas = { source: vdata };
        Vue.http.post(this.URL + '/v1/reservation/create', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Reservation_Cancel(numero, callback) {
        var datas = { numero: numero };
        Vue.http.post(this.URL + '/v1/reservation/cancel', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Reservation_Nostock(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/reservation/nostock', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Iban_verification(iban, callback) {
        var datas = { iban }
        Vue.http.post(this.URL + '/v1/iban/verification', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    edit_dispo(resa, callback) {
        var datas = { resa };
        Vue.http.post(this.URL + '/v1/edit_dispo', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        });
    }

    get_dispo(city, agency, today, callback) {
        var datas = { city, agency, today };
        Vue.http.post(this.URL + '/v1/get_dispo', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        });
    }

    get_dispo_between_timestamp(city, agency, start, end, callback) {
        var datas = { city, agency, start, end };
        Vue.http.post(this.URL + '/v1/get_dispo_between_timestamp', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        });
    }

    get_dispo_between_date_by_type(city, agency, type, start, end, callback) {
        var datas = { city, agency, type, start, end };
        Vue.http.post(this.URL + '/v1/get_dispo_between_date_by_type', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        });
    }

    get_dispo_between_date_by_types_and_agency(fmData, callback) {
        var datas = { fmData };
        Vue.http.post(this.URL + '/v1/get_dispo_between_date_by_types_and_agency', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        });
    }
    get_dispo_by_code_client(codeClient, callback) {
        var datas = { codeClient };
        Vue.http.post(this.URL + '/v1/get_dispo_by_code_client', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        });
    }

    get_agency_liste(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/get_agency_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        });
    }

    get_rdv_type_liste(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/get_rdv_type_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        });
    }

    get_no_actif_contract_by_client(code_client, callback) {
        var datas = { code_client };
        Vue.http.post(this.URL + '/v1/get_no_actif_contract_by_client', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        });
    }

    get_actif_contract_by_client(code_client, callback) {
        var datas = { code_client };
        Vue.http.post(this.URL + '/v1/get_actif_contract_by_client', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.bodyText));
        });
    }

}

export default new DataAPI()