<template>
  <div>
    <div class="data-form-background" @click="ctrlback"></div>
    <div class="data-form">
      <div class="line" style="border-bottom: solid 1px var(--C3)">
        <div style="padding-left: 20px">
          <h2 @click="ctrlback">
            <i class="far fa-arrow-alt-circle-left" style="cursor: pointer"></i>
            {{ title }}
          </h2>
        </div>
      </div>
      <div class="line" v-for="control in controls" :key="control.databind">
        <div
          class="form-input"
          v-if="control.datatype == 'text'"
          :style="{ display: control.display }"
        >
          <label>{{ control.description }}</label>
          <input
            :class="{ 'finput-error': control.condvalue }"
            type="text"
            v-model="control.datavalue"
            :placeholder="control.description"
          />
          <label
            v-if="control.condvalue == true"
            style="text-transform: none; font-size: 10px; color: red"
            >{{ control.condmsg }}</label
          >
        </div>
        <div class="form-input" v-if="control.datatype == 'label'">
          <p>{{ control.description }}</p>
        </div>
        <div class="form-input" v-if="control.datatype == 'html'">
          <div
            style="width: calc(100% - 40px)"
            v-html="control.description"
          ></div>
        </div>
        <div class="form-input" v-if="control.datatype == 'link'">
          <p>{{ control.description }}</p>
          <a :href="control.link" target="_blank">Télécharger le document</a>
        </div>
        <div class="form-input" v-if="control.datatype == 'date'">
          <label>{{ control.description }}</label>
          <input
            :class="{ 'finput-error': control.condvalue }"
            type="date"
            v-model="control.datavalue"
          />
          <label
            v-if="control.condvalue == true"
            style="text-transform: none; font-size: 10px; color: red"
            >{{ control.condmsg }}</label
          >
        </div>
        <div class="form-input" v-if="control.datatype == 'select'">
          <label>{{ control.description }}</label>
          <select
            :class="{ 'finput-error': control.condvalue }"
            v-model="control.datavalue"
          >
            <option
              :key="item.value"
              v-for="item in control.datalist"
              :value="item.value"
            >
              {{ item.label }}
            </option>
          </select>
          <label
            v-if="control.condvalue == true"
            style="text-transform: none; font-size: 10px; color: red"
            >{{ control.condmsg }}</label
          >
        </div>
        <div class="form-input" v-if="control.datatype == 'file'">
          <label>{{ control.description }}</label>
          <input
            :class="{ 'finput-error': control.condvalue }"
            type="file"
            :id="control.databind"
            accept="application/pdf image/png image/jpg"
          />
          <label
            v-if="control.condvalue == true"
            style="text-transform: none; font-size: 10px; color: red"
            >{{ control.condmsg }}</label
          >
        </div>
      </div>
      <div
        class="line"
        style="
          text-align: right;
          margin-top: 40px;
          margin-bottom: 40px;
          width: calc(100% - 20px);
        "
      >
        <button @click="ctrlsave" class="fbutton fbutton-big">
          Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    controls: Array,
    fxback: Function,
    fxsave: Function,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    ctrlback: function () {
      this.fxback();
    },
    ctrlsave: function () {
      var obj = {};
      var has_error = false;
      this.controls.forEach(function (p) {
        if (
          p.datatype != "file" &&
          p.datatype != "label" &&
          p.datatype != "html"
        ) {
          obj[p.databind] = p.datavalue;
          if (p.condfx != undefined) {
            if (p.condfx(p.datavalue) == false) {
              p.condvalue = true;
              has_error = true;
            } else {
              p.condvalue = false;
            }
          }
        }
        if (p.datatype == "file") {
          obj[p.databind] = document.getElementById(p.databind).files[0];
          if (p.condfx != undefined) {
            if (
              p.condfx(document.getElementById(p.databind).files[0]) == false
            ) {
              p.condvalue = true;
              has_error = true;
            } else {
              p.condvalue = false;
            }
          }
        }
      });
      if (!has_error) {
        this.fxsave(obj);
      }
    },
  },
};
</script>

<style>
</style>