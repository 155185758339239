<template>
  <div>
    <v-dialog v-model="showPdf">
      <div style="position:relative">
        <div style="position: absolute; right: 5px; top:0px; z-index: 2;">
          <v-icon color="red" @click="showPdf = false" size="25px">mdi-close-circle</v-icon>
        </div>
        <object id="pdfViewer" :data="filebase64" type="application/pdf" width="100%" style="height: 90vh;width: 100%;">
        </object>
        <!-- <vue-pdf-app v-if="filebase64" style="height: 80vh;width: 500px;" :config="config" :isSidebarHidden="true"
                :isFindbarHidden="true" :pdf="filebase64"></vue-pdf-app> -->
      </div>
    </v-dialog>
    <div class="application">
      <div class="line showpc" style="height:60px;border-bottom:solid 1px var(--C3);">
        <div class="line-33">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:left;margin-left:15px;">
            <li style="line-height:60px;">
              <img src="../assets/rdt13.png" alt="" style="height:50px;margin-top:5px;">
            </li>
          </ul>
        </div>
        <div class="line-34">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:center;">
            <li style="line-height:60px;">
              <img src="../assets/logo.png" alt="" style="cursor:pointer;height:50px;margin-top:5px;"
                @click="navigation('board')">
            </li>
          </ul>
        </div>
        <div class="line-33">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:right;margin-right:15px;">
            <li style="line-height:60px;">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div class="line showmobile" style="height:60px;border-bottom:solid 1px var(--C3);">
        <div class="line-50">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:left;margin-left:15px;">
            <li style="line-height:60px;">
              <img src="../assets/logo.png" alt="" style="height:50px;margin-top:5px;">
            </li>
          </ul>
        </div>
        <div class="line-50">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:right;margin-right:15px;">
            <li style="line-height:60px;">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div class="line" style="text-align:center;">
        <h1>Vos factures</h1>
        <h3>Historique de vos factures de locations</h3>
      </div>
      <div class="line-m" style="margin-top:40px;">
        <table class="ftable">
          <tr>
            <th>Numéro de facture</th>
            <th>Date de facture</th>
            <!-- <th>Etat</th> -->
            <th>Prix</th>
            <!-- <th>Montant TTC</th> -->
            <th>Statut</th>
            <th></th>
          </tr>
          <tr v-for="invoice in list" :key="invoice">
            <td>{{ invoice.invoice_number }}</td>
            <td>{{ formatDate(invoice.invoice_date.split('-')) }}</td>
            <td>{{ invoice.amount }}€</td>
            <td>{{ invoice.state }}</td>
            <!-- <td>{{invoice.invoice_number}}</td>
            <td>{{invoice.invoice_date|moment('DD/MM/YYYY')}}</td>
            <td>{{invoice.state}}</td>
            <td>{{invoice.rental_number}}</td>
            <td style="text-align:right;">{{invoice.amount|money}}</td>-->
            <td style="color:var(--BUTTON);text-align:center;cursor:pointer;" @click="donwloadInvoice(invoice)"><i
                class='fa fa-upload'></i></td>
          </tr>
        </table>
      </div>
    </div>
    <loadingform v-if="loading"></loadingform>
  </div>
</template>

<script>
import engine from "../core/DataAPI";
import login from "../core/IconLogin";
import loadingform from "../core/LoadingForm";
// import moment from 'moment'

// const Buffer = require('buffer').Buffer

export default {
  components: {
    login, loadingform
  },
  data() {
    return {
      showPdf: false,
      filebase64: undefined,
      loading: false,
      account: {},
      vdate: '',
      list: []
    }
  },
  created: function () {
    var tmain = this;
    this.$moment.locale('fr');
    tmain.loading = true;
    engine.Account_Info(function (pkg) {
      if (pkg.code != 0) {
        tmain.loading = false;
        tmain.$router.push('/');
      } else {
        tmain.account = pkg.data[0];
        // engine.invoices_exist(function (pkginvoices) {
        //   tmain.loading = false;
        //   console.log("invoices_exist", pkginvoices)
        //   if (pkginvoices.code == 0) {
        //     tmain.list = pkginvoices.data;
        //   }
        // });
        engine.Invoices_List(function (data) {
          if (data.code == 0) {
            console.log("Invoices_List", data);
            tmain.list = data.data
          }
          console.log("liste", tmain.list);
          tmain.loading = false;
        })
      }
    });
  },
  methods: {
    formatDate(date) {
      return date[2] + '/' +date[1] + '/' +date[0]
    },
    donwloadInvoice: function (fileData) {
      // let that = this

      // console.log("File_Invoice_Download", fileData);
      // engine.File_Download_invoice(fileData.invoice_number, function(data){
      //   console.log("File_Invoice_Download", data);
      // })

      engine.File_Download_invoice(fileData.invoice_number,function(pkgfile){
        var extname = ".pdf";
        var fileURL = window.URL.createObjectURL(new Blob([pkgfile.data]));
        var download_link = document.createElement('a');
        download_link.href = fileURL;
        download_link.download = fileData.invoice_number + extname;
        download_link.target = "_blank";
        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);
      });


      // engine.File_Invoice_Download(this.account.code, fileName, function (pkgfile) {
      //   console.log("pkgfile", pkgfile);
      //   that.filebase64 = "data:application/pdf;base64," + new Buffer.from(pkgfile.data.fileBinary).toString('base64');
      //   console.log('filebase64', that.filebase64)
      //   that.showPdf = true
      //   // var extname = ".pdf";
      //   // var fileURL = window.URL.createObjectURL(new Blob([pkgfile.data]));
      //   // var download_link = document.createElement('a');
      //   // download_link.href = fileURL;
      //   // download_link.download = invoice.invoice_number + extname;
      //   // download_link.target = "_blank";
      //   // document.body.appendChild(download_link);
      //   // download_link.click();
      //   // document.body.removeChild(download_link);
      // });
    },
    navigation: function (app) {
      var tmain = this;
      tmain.$router.push('/' + app);
    }
  }
}
</script>

<style></style>
