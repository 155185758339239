<template>
    <div>
        <div>
            <!-- <button v-if="JSON.stringify(account)=='{}'" @click="account_login" class='fbutton'>Connexion</button> -->
            <!-- <button v-if="JSON.stringify(account)!='{}'" @click="switchpanel" class='fbutton-circle'>{{account.fullname[0].toUpperCase()}}</button> -->
            <v-chip v-if="JSON.stringify(account)!='{}'" @click="switchpanel" class='fbutton-circle'>{{account.fullname[0].toUpperCase()}}</v-chip>
        </div>
        <div style="position:absolute;box-shadow:0px 0px 15px var(--C3);text-align:center;background-color:#fff;z-index:1;right:15px;top:57px;height:300px;width:350px;border:solid 1px var(--C3);border-radius:10px;" v-if="viewpanel">
            <div class='line' style="margin-top:40px;line-height:0;">
                <img src="../assets/logo.png" style="height:40px"/>
                <h2 class="mt-3" style="margin-bottom:26px;text-transform:capitalize;">{{account.fullname}}</h2>
                <label style="font-size:13px;color:var(--C2);">{{account.email}}</label>
            </div>
            <div class="line" style="line-height:0;margin-top:40px;">
                <label @click="account_manage" style="cursor:pointer;padding:5px 12px;border-radius:15px;font-size:13px;border:solid 1px var(--C3);">Gérer votre compte levélo+</label>
            </div>
            <div class="line" style="line-height:0;margin-top:40px;">
                <v-btn outlined @click="disconnect" class='fbutton'>Déconnexion</v-btn>
            </div>
        </div>
    </div>
</template>

<script>

import engine from './DataAPI';

export default {
    
    props:{
        account:Object
    },
    data(){
        return {
            viewpanel:false
        };
    },
    created() {
        
    },
    methods:{
        switchpanel: function(){
            var tmain = this;
            tmain.viewpanel = !tmain.viewpanel;
        },
        account_login: function(){
            var tmain = this;
            tmain.$router.push('/account/login');
        },
        account_manage: function(){
            var tmain = this;
            tmain.$router.push('/account');
        },
        disconnect:function(){
            var tmain = this;
            engine.Account_Logout(function(pkg){
                if (pkg.code==0){
                    tmain.$router.push('/');
                }
            });            
        }
    }
}
</script>

<style>
</style>