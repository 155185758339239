<template>
  <div>
    <div class="application">
      <div
        class="line showpc"
        style="height: 60px; border-bottom: solid 1px var(--C3)"
      >
        <div class="line-33">
          <ul
            style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: left;
              margin-left: 15px;
            "
          >
            <li style="line-height: 60px">
              <img
                src="../assets/rdt13.png"
                alt=""
                style="height: 50px; margin-top: 5px"
              />
            </li>
          </ul>
        </div>
        <div class="line-34">
          <ul
            style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: center;
            "
          >
            <li style="line-height: 60px">
              <img
                src="../assets/logo.png"
                alt=""
                style="height: 50px; margin-top: 5px"
              />
            </li>
          </ul>
        </div>
        <div class="line-33">
          <ul
            style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: right;
              margin-right: 15px;
            "
          >
            <li style="line-height: 60px">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="line showmobile"
        style="height: 60px; border-bottom: solid 1px var(--C3)"
      >
        <div class="line-50">
          <ul
            style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: left;
              margin-left: 15px;
            "
          >
            <li style="line-height: 60px">
              <img
                src="../assets/logo.png"
                alt=""
                style="height: 50px; margin-top: 5px"
              />
            </li>
          </ul>
        </div>
        <div class="line-50">
          <ul
            style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: right;
              margin-right: 15px;
            "
          >
            <li style="line-height: 60px">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div class="line my-1" style="text-align: center">
        <h1 style="text-transform: capitalize">
          Bienvenue {{ account.fullname }}
        </h1>
        <h3 class="mt-1 mb-3">
          Gérer votre compte en toute simplicité : <br />consulter vos factures,
          modifier vos informations personnelles et effectuer vos réservations
          de vélos.
        </h3>
      </div>
      <div
        class="line-m"
        style="
          text-align: center;
          width: 100%;
          padding-left: 10%;
          padding-right: 10%;
        "
        align="center"
      >
        <v-row justify="center" align="center">
          <v-col>
            <div
              v-if="account.nb_contrats < 1"
              class="board-item"
              @click="navigation('reservation')"
            >
              <div class="board-icon">
                <i class="fa fa-plus"></i>
              </div>
              <div class="board-text">
                <h2>Réserver un vélo</h2>
                <label style="color: var(--C2)"
                  >Effectuer une réservation de vélos et réaliser le paiement en
                  ligne</label
                >
              </div>
            </div>
            <div v-if="account.nb_contrats >= 1" class="board-item">
              <div class="board-icon">
                <i class="fa fa-plus"></i>
              </div>
              <div class="board-text">
                <h2>Réserver un vélo</h2>
                <label style="color: red"
                  >Vous avez déjà réaliser un contrat de location</label
                >
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="board-item" @click="navigation('account')">
              <div class="board-icon">
                <i class="fa fa-user"></i>
              </div>
              <div class="board-text">
                <h2>Votre compte</h2>
                <label style="color: var(--C2)"
                  >Modifier vos informations personnelles et envoyer des
                  documents justificatifs</label
                >
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="board-item" @click="navigation('invoices')">
              <div class="board-icon">
                <i class="fa fa-file-invoice"></i>
              </div>
              <div class="board-text">
                <h2>Vos factures</h2>
                <label style="color: var(--C2)"
                  >Historique de vos factures de locations de vélos et
                  accessoires</label
                >
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="board-item" @click="navigation('planning')">
              <div class="board-icon">
                <v-icon color="rgb(232, 80, 29)" class="board-icon" style="font-size: 28px">mdi-calendar</v-icon>
                <!-- <i class="fa fa-file-invoice"></i> -->
              </div>
              <div class="board-text">
                <h2>Planning</h2>
                <label style="color: var(--C2)"
                  >Gestion et prise de rendez-vous</label
                >
              </div>
            </div>
          </v-col>
        </v-row>
        <ul class="board-menu">
          <!-- <li>
            <div
              v-if="account.nb_contrats < 1"
              class="board-item"
              @click="navigation('reservation')"
            >
              <div class="board-icon">
                <i class="fa fa-plus"></i>
              </div>
              <div class="board-text">
                <h2>Réserver un vélo</h2>
                <label style="color: var(--C2)"
                  >Effectuer une réservation de vélos et réaliser le paiement en
                  ligne</label
                >
              </div>
            </div>
            <div v-if="account.nb_contrats >= 1" class="board-item">
              <div class="board-icon">
                <i class="fa fa-plus"></i>
              </div>
              <div class="board-text">
                <h2>Réserver un vélo</h2>
                <label style="color: red"
                  >Vous avez déjà réaliser un contrat de location</label
                >
              </div>
            </div>
          </li> -->
          <!-- <li>
            <div class="board-item" @click="navigation('account')">
              <div class="board-icon">
                <i class="fa fa-user"></i>
              </div>
              <div class="board-text">
                <h2>Votre compte</h2>
                <label style="color: var(--C2)"
                  >Modifier vos informations personnelles et envoyer des
                  documents justificatifs</label
                >
              </div>
            </div>
          </li> -->
          <!-- <li>
            <div class="board-item" @click="navigation('invoices')">
              <div class="board-icon">
                <i class="fa fa-file-invoice"></i>
              </div>
              <div class="board-text">
                <h2>Vos factures</h2>
                <label style="color: var(--C2)"
                  >Historique de vos factures de locations de vélos et
                  accessoires</label
                >
              </div>
            </div>
          </li> -->
          <!-- <li>
            <div class="board-item" @click="navigation('invoices')">
              <div class="board-icon">
                <i class="fa fa-file-invoice"></i>
              </div>
              <div class="board-text">
                <h2>AUTRES</h2>
                <label style="color: var(--C2)"
                  >Historique de vos factures de locations de vélos et
                  accessoires</label
                >
              </div>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import engine from "../core/DataAPI";
import login from "../core/IconLogin";

export default {
  components: {
    login,
  },
  data() {
    return {
      account: {},
    };
  },
  created: function () {
    var tmain = this;
    tmain.loading = true;
    tmain.datenow = tmain.$moment().format("YYYY-MM-DD");
    engine.Account_Info(function (pkg) {
      if (pkg.code != 0) {
        tmain.$router.push("/");
      } else {
        tmain.account = pkg.data[0];
        /////// TEST /////////
        // console.log("TEST");
        // tmain.account.nb_contrats = 0
        /////// TEST /////////
      }
      tmain.loading = false;
    });
  },
  methods: {
    navigation: function (app) {
      var tmain = this;
      tmain.$router.push("/" + app);
    },
  },
};
</script>
<style>
</style>
