<template>
  <v-app>
    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
export default {
  created() {
    this.$moment.locale("fr");
  },
};
</script>

<style src="./accen.css"></style>