<template>
  <div>
    <div class="application">
      <div class="line showpc" style="height: 60px; border-bottom: solid 1px var(--C3)">
        <div class="line-33">
          <ul style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: left;
              margin-left: 15px;
            ">
            <li style="line-height: 60px">
              <img src="../assets/rdt13.png" alt="" style="height: 50px; margin-top: 5px" />
            </li>
          </ul>
        </div>
        <div class="line-34">
          <ul style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: center;
            ">
            <li style="line-height: 60px">
              <img src="../assets/logo.png" alt="" style="cursor: pointer; height: 50px; margin-top: 5px"
                @click="backAccount" />
            </li>
          </ul>
        </div>
        <div class="line-33">
          <ul style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: right;
              margin-right: 15px;
            ">
            <li style="line-height: 60px">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div class="line showmobile" style="height: 60px; border-bottom: solid 1px var(--C3)">
        <div class="line-50">
          <ul style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: left;
              margin-left: 15px;
            ">
            <li style="line-height: 60px">
              <img src="../assets/logo.png" alt="" style="height: 50px; margin-top: 5px" @click="backAccount" />
            </li>
          </ul>
        </div>
        <div class="line-50">
          <ul style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: right;
              margin-right: 15px;
            ">
            <li style="line-height: 60px">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="viewdiv == 'reservation'">
        <div class="line" style="text-align: center">
          <h1>Nouvelle réservation</h1>
          <h3>Effectuez votre réservation en ligne</h3>
        </div>
        <div class="line-a" style="margin-top: 40px; margin-bottom: 40px">
          <div v-if="step >= 1" class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 1 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez le type de vélo que vous souhaitez louer
              </p>
            </div>
            <ul class="family-bloc-text">
              <li v-for="material in list_materials" :key="material.id" @click="validS1(material)">
                <div class="family-bloc-title">{{ material.label }}</div>
                <div v-if="material.qty > 0">
                  <div class="family-bloc-icon">
                    <img :src="'./media/' + material.logo + '.png'" alt=""
                      :class="{ 'border-bloc-selected': material.selected }" style="height: 100px" />
                  </div>
                  <div class="family-bloc-legend">
                    {{ material.description }}
                  </div>
                </div>
                <div v-if="material.qty <= 0">
                  <div class="family-bloc-icon">
                    <img :src="'./media/' + material.logo + '-nb.png'" alt=""
                      :class="{ 'border-bloc-selected': material.selected }" style="height: 100px" />
                  </div>
                  <div class="family-bloc-legend" style="font-weight:bold;color:black">En rupture de stock, mais bientôt
                    de retour !
                    <div class="my-2" style="">
                      <a class="pa-1 hover"
                        style="border-radius: 3px; color:white;font-weight:bold;text-decoration:none;background-color:var(--BUTTON);font-size:13px;"
                        target="_blank" href="https://www.leveloplus.com/listedattente">
                        <br>
                        <br>
                        Être notifié quand ce vélo sera
                        disponible</a>
                    </div>
                  </div>
                </div>
                <div class="family-bloc-select"></div>
              </li>
            </ul>
            <div id="blocacc" v-if="JSON.stringify(material_selected) != '{}' &&
              material_selected.accessories.length > 0
            " class="account-bloc-title" style="border-top: solid 1px var(--C3)">
              <label style="font-size: 18px">Accessoires</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez les accessoires au nombre de
                {{ material_selected.qtymaxaccessories }} au maximum
              </p>
            </div>
            <ul v-if="JSON.stringify(material_selected) != '{}' &&
              material_selected.accessories.length > 0
            " class="accessory-bloc-text">
              <li v-for="acc in material_selected.accessories" :key="acc.id">
                <div class="family-bloc-title">{{ acc.label }}</div>
                <div class="family-bloc-icon">
                  <img :src="'./media/' + acc.logo + '.png'" alt="" style="height: 100px" />
                </div>
                <ul v-if="acc.qties.length > 1" class="accessory-bloc-qty">
                  <li v-for="q in acc.qties" :key="q.value" @click="validS1acc(acc, q)">
                    <label :class="{ 'border-bloc-selected': q.selected == true }">{{ q.value }}</label>
                  </li>
                </ul>
                <ul v-if="acc.qties.length == 1" class="accessory-bloc-qty">
                  <li v-for="q in acc.qties" :key="q.value" @click="validS1acc(acc, q)">
                    <label :class="{ 'border-bloc-selected': q.selected == true }" style="font-weight:bold">Victime de
                      son succès
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div id="bloc2" v-if="step >= 2" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 2 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez l'agence de retrait
              </p>
            </div>
            <v-col>
              <v-row v-for="agency in list_agencies" v-bind:key="agency.id" class="pr-1"
                style="border-top:solid 1px lightgrey" :class="{
                  // 'account-bloc-text': true,
                  'bloc-selected': agency.selected == true,
                }" @click="validS2(agency)">
                <v-col cols="10" class="agency-bloc-legend">{{ agency.relais }}</v-col>
                <!-- <v-col cols="5" class="agency-bloc-legend">{{ agency.company }}</v-col>
                <v-col cols="5" class="agency-bloc-value" style="font-size: 12px; color: var(--C2)">
                  {{ agency.address }} {{ agency.zipcode }} {{ agency.city }}
                </v-col> -->
                <v-col class="account-bloc-icon" cols="2">
                  <fa-icon v-if="!(agency.selected == true)" :icon="['far', 'circle']" />
                  <fa-icon v-if="agency.selected == true" :icon="['far', 'check-circle']" />
                </v-col>
              </v-row>
            </v-col>
            <!-- <div v-for="agency in list_agencies" v-bind:key="agency.id" :class="{
              'account-bloc-text': true,
              'bloc-selected': agency.selected == true,
            }" @click="validS2(agency)">
              <div class="agency-bloc-legend">{{ agency.company }}</div>
              <div class="agency-bloc-value" style="font-size: 12px; color: var(--C2)">
                {{ agency.address }} {{ agency.zipcode }} {{ agency.city }}
              </div>
              <div class="account-bloc-icon">
                <fa-icon v-if="!(agency.selected == true)" :icon="['far', 'circle']" />
                <fa-icon v-if="agency.selected == true" :icon="['far', 'check-circle']" />
              </div>
            </div> -->
            <calendar id="calendar" v-if="list_agencies.find((val) => val.selected == true)"
              :agency="list_agencies.find((val) => val.selected == true)" :list_agencies="list_agencies"
              @validRdvS2="validRdvS2($event)"></calendar>
          </div>
        </div>
        <div id="bloc3" v-if="step >= 3" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 3 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez le type d'abonnement annuel
              </p>
            </div>
            <div v-for="subscription in material_selected.subscriptions" v-bind:key="subscription.id" :class="{
              'account-bloc-text': true,
              'bloc-selected': subscription.selected == true,
            }" @click="validS3(subscription)">
              <div class="account-bloc-legend">{{ subscription.label }}</div>
              <div class="account-bloc-value" style="font-size: 12px; color: var(--C2)">
                {{ subscription.description }}
              </div>
              <div class="account-bloc-icon">
                <fa-icon v-if="!(subscription.selected == true)" :icon="['far', 'circle']" />
                <fa-icon v-if="subscription.selected == true" :icon="['far', 'check-circle']" />
              </div>
            </div>
          </div>
        </div>
        <div id="bloc4" v-if="step >= 4" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 4 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Sélectionnez le type de règlement
              </p>
            </div>
            <div v-for="pric in subscription_selected.prices" :key="pric.id" :class="{
              'account-bloc-text': true,
              'bloc-selected': pric.selected == true,
            }" @click="validS4(pric)">
              <div class="account-bloc-legend">{{ pric.label }}</div>
              <div class="account-bloc-value" style="font-size: 12px; color: var(--C2)">
                {{ pric.description }}
              </div>
              <div class="account-bloc-icon" style="text-align: left; padding: 20px 20px 20px 0px">
                <label v-if="pric.id == 1" style="font-weight: bold">{{
                  list_prices[0].amount | money
                }}</label>
                <label v-if="pric.id == 2" style="font-weight: bold">{{
                  list_prices[1].amount | money
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <div id="bloc5" v-if="step >= 5" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Etape 5 / {{ maxstep }}</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Insérer les documents adminitratifs nécessaires pour la location
              </p>
            </div>
            <div v-if="subscription_selected.id >= 1">
              <div class="account-bloc-text" @click="editprofile">
                <div class="account-bloc-legend">IBAN</div>
                <div v-if="account.iban == ''" style="color: red; font-weight: bold" class="account-bloc-value">
                  Veuillez renseigner votre IBAN
                </div>
                <div v-if="account.iban != ''" class="account-bloc-value">
                  <div class="account-bloc-value" style="color: red; font-weight: bold; margin-top: -21px"
                    v-if="is_valid_iban === false">
                    <i class="fa fa-info-circle"></i> Iban non valide
                  </div>
                  <div class="">{{ account.iban }}</div>
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div v-if="piece_cni == true" class="account-bloc-text">
                <div class="account-bloc-legend">
                  CNI / Passeport en cours de validité
                </div>
                <div class="account-bloc-value" @click="downloadFile('cni')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('cni')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_cni == false" class="account-bloc-text" @click="uploadFile('cni')">
                <div class="account-bloc-legend">
                  CNI / Passeport en cours de validité
                </div>
                <div class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div v-if="piece_justificatif == true" class="account-bloc-text">
                <div class="account-bloc-legend">
                  Justificatif de domicile de moins de 3 mois
                </div>
                <div class="account-bloc-value" @click="downloadFile('justificatif')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('justificatif')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_justificatif == false" class="account-bloc-text" @click="uploadFile('justificatif')">
                <div class="account-bloc-legend">
                  Justificatif de domicile de moins de 3 mois
                </div>
                <div class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
              <div v-if="piece_rib == true" class="account-bloc-text">
                <div class="account-bloc-legend">RIB</div>
                <div class="account-bloc-value" @click="downloadFile('rib')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('rib')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_rib == false" class="account-bloc-text" @click="uploadFile('rib')">
                <div class="account-bloc-legend">RIB</div>
                <div class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
            <div v-if="subscription_selected.id == 2">
              <div v-if="piece_cmuc == true" class="account-bloc-text">
                <div class="account-bloc-legend">Attestation CMUC</div>
                <div class="account-bloc-value" @click="downloadFile('cmuc')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('cmuc')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_cmuc == false" class="account-bloc-text" @click="uploadFile('cmuc')">
                <div class="account-bloc-legend">Attestation CMUC</div>
                <div class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
            <div v-if="subscription_selected.id == 3">
              <div v-if="piece_mobilite == true" class="account-bloc-text">
                <div class="account-bloc-legend">
                  Attestation Pass Mobilités
                </div>
                <div class="account-bloc-value" @click="downloadFile('passmobilite')">
                  Consulter le document
                </div>
                <div class="account-bloc-icon" @click="uploadFile('passmobilite')">
                  <i class="fa fa-upload"></i>
                </div>
              </div>
              <div v-if="piece_mobilite == false" class="account-bloc-text" @click="uploadFile('passmobilite')">
                <div class="account-bloc-legend">
                  Attestation Pass Mobilités
                </div>
                <div class="account-bloc-value" style="color: red; font-weight: bold">
                  <i class="fa fa-info-circle"></i> Document manquant
                </div>
                <div class="account-bloc-icon">
                  <i class="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="bloc6" v-if="step >= 5 && account.is_main_bank == 'non'" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size: 18px">Informations concernant le titulaire du compte bancaire</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Fournir les informations complémentaires concernant le titulaire
                du compte
              </p>
            </div>
            <div v-if="banq_account.nom == ''" class="account-bloc-text" @click="editbanqaccount">
              <div class="account-bloc-legend">TITULAIRE DU COMPTE</div>
              <div style="color: red; font-weight: bold" class="account-bloc-value">
                <i class="fa fa-info-circle"></i> Veuillez renseigner les
                informations du titulaire du compte
              </div>
              <div class="account-bloc-icon">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
            <div v-if="banq_account.nom != ''" class="account-bloc-text" @click="editbanqaccount">
              <div class="account-bloc-legend">TITULAIRE DU COMPTE</div>
              <div class="account-bloc-value">
                {{ banq_account.prenom }} {{ banq_account.nom }}
              </div>
              <div class="account-bloc-icon">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
            <div v-if="piece_cni_titulaire == true" class="account-bloc-text">
              <div class="account-bloc-legend">
                CNI / Passeport en cours de validité
              </div>
              <div class="account-bloc-value" @click="downloadFile('cni_titulaire')">
                Consulter le document
              </div>
              <div class="account-bloc-icon" @click="uploadFile('cni_titulaire')">
                <i class="fa fa-upload"></i>
              </div>
            </div>
            <div v-if="piece_cni_titulaire == false" class="account-bloc-text" @click="uploadFile('cni_titulaire')">
              <div class="account-bloc-legend">
                CNI / Passeport en cours de validité
              </div>
              <div class="account-bloc-value" style="color: red; font-weight: bold">
                <i class="fa fa-info-circle"></i> Insérer la CNI ou le passeport
                du titulaire du compte bancaire
              </div>
              <div class="account-bloc-icon">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
            <div v-if="piece_attestation == true" class="account-bloc-text">
              <div class="account-bloc-legend">Procuration</div>
              <div class="account-bloc-value" @click="downloadFile('attestation')">
                Consulter le document
              </div>
              <div class="account-bloc-icon" @click="editbanqfile">
                <i class="fa fa-upload"></i>
              </div>
            </div>
            <div v-if="piece_attestation == false" class="account-bloc-text" @click="editbanqfile">
              <div class="account-bloc-legend">Procuration</div>
              <div class="account-bloc-value" style="color: red; font-weight: bold">
                <i class="fa fa-info-circle"></i> Insérer le formulaire de
                procuration complété et signé
              </div>
              <div class="account-bloc-icon">
                <i class="fa fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div id="bloc_recap" v-if="visu_payment" class="line-a" style="margin-bottom: 40px">
          <div class="account-bloc">
            <div class="recap-bloc-title">
              <label style="font-size: 18px">Récaptitulatif</label>
              <p style="
                  color: var(--C2);
                  font-size: 13px;
                  padding: 0px;
                  margin: 0px;
                ">
                Récapitulatif de votre réservation
              </p>
            </div>
            <!-- <div class="recap-bloc-title" style="background-color: var(--BUTTON); border-bottom: none">
              <label style="font-size: 18px; color: #fff"><i class="fa fa-exclamation"></i></label>
              <p style="color: #fff; font-size: 13px; padding: 0px; margin: 0px">
                Le retrait du vélo est uniquement sur RDV. Une fois la
                réservation effectuée,<br />veuillez suivre le lien indiqué dans
                votre e-mail de confirmation.
              </p>
            </div> -->
            <div class="recap-bloc-text" style="background-color: var(--C2); color: #fff; border-top: none">
              <div style="
                  padding: 20px;
                  text-align: center;
                  width: calc(100% - 40px);
                  float: left;
                ">
                <label style="font-size: 16px">{{ lbl_description }}</label>
                <br />
                <label style="font-size: 32px">{{ lbl_amount | money }}</label><br />
                <label style="font-size: 12px">{{ lbl_payment_info_cb }}</label>
                <br />
                <label style="font-size: 16px">Abonnement {{ subscription_selected.label }}</label>
              </div>
            </div>
            <div class="recap-bloc-text" style="background-color: #fff; color: var(--C2); border-top: none"
              @click="acceptcguv = !acceptcguv">
              <div style="
                  padding: 20px;
                  text-align: center;
                  width: calc(100% - 40px);
                  float: left;
                ">
                <label v-if="acceptcguv" style="font-size: 16px">
                  <fa-icon :icon="['fas', 'check-square']" />
                  J'accepte les
                  <a style="color: var(--BUTTON); font-weight: bold" target="_blank"
                    href="https://www.leveloplus.com/cguv">CGUV</a>
                  et la
                  <a target="_blank" style="color: var(--BUTTON); font-weight: bold"
                    href="https://www.leveloplus.com/politique-de-confidentialite">politique de confidentialité</a>
                </label>
                <label v-if="!acceptcguv" style="font-size: 16px">
                  <fa-icon :icon="['fas', 'square']" />
                  J'accepte les
                  <a target="_blank" style="color: var(--BUTTON); font-weight: bold"
                    href="https://www.leveloplus.com/cguv">CGUV</a>
                  et la
                  <a target="_blank" style="color: var(--BUTTON); font-weight: bold"
                    href="https://www.leveloplus.com/politique-de-confidentialite">politique de confidentialité</a>
                </label>
              </div>
            </div>
            <div class="recap-bloc-text" style="
                padding-top: 20px;
                padding-bottom: 20px;
                text-align: center;
                width: 100%;
              ">
              <div style="
                  padding: 10px;
                  text-align: center;
                  width: calc(100% - 20px);
                  float: left;
                ">
                <button v-if="acceptcguv" @click="payment_start" class="fbutton fbutton-big">
                  Régler la réservation
                </button>
                <button v-if="!acceptcguv" class="fbutton fbutton-big" style="background-color: var(--C2)">
                  Régler la réservation
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="viewdiv == 'retour'">
        <div class="line" style="text-align: center; margin-top: 120px">
          <i v-if="msgretour == 'effectue'" style="color: var(--BUTTON)" class="far fa-smile-beam fa-4x"></i>
          <i v-if="msgretour != 'effectue'" style="color: var(--BUTTON)" class="far fa-frown fa-4x"></i>
          <h1>{{ msgtitle }}</h1>
          <h3>{{ msgsubtitle }}</h3>
          <!-- <button
            v-if="msgretour == 'effectue'"
            style="margin-right: 10px"
            class="fbutton"
            @click="goRDV"
          >
            Prise de RDV
          </button> -->
          <button class="fbutton" @click="backAccount">
            Retour à votre compte
          </button>
        </div>
      </div>
      <div v-if="viewdiv == 'nostock'">
        <div class="line" style="text-align: center; margin-top: 120px">
          <i style="color: var(--BUTTON)" class="far fa-frown fa-4x"></i>
          <h1>Rupture de stock</h1>
          <div align=center>
            <h3 style="width:90%; max-width:700px; padding: 10px;">
            Notre flotte étant actuellement louée dans sa totalité, nous vous conseillons de vous connecter
            régulièrement afin de
            pouvoir bénéficier d'une éventuelle restitution. Un mail pourra vous être envoyé dès la réception de stocks
            supplémentaires.
            Vous aurez alors 72h pour bénéficier d'un vélo sans quoi votre nom sera alors automatiquement supprimé de la
            liste
            d'attente
          </h3>
          </div>
          <h3 style="padding: 10px;">Veuillez cliquer
            sur le bouton ci-dessous afin de vous inscrire dans notre liste
            d'attente.
          </h3>
          <a style="margin-right: 10px;color:white;text-decoration: none;" class="fbutton"
            href="https://www.leveloplus.com/listedattente">
            S'inscrire sur la liste d'attente
          </a>
          <button class="fbutton" @click="backAccount">
            Retour à votre compte
          </button>
        </div>
      </div>
      <div v-if="viewdiv == 'nostock_valid'">
        <div class="line" style="text-align: center; margin-top: 120px">
          <i style="color: var(--BUTTON)" class="far fa-smile-beam fa-4x"></i>
          <h1>Inscription dans la liste d'attente</h1>
          <h3>
            Votre inscription dans la liste d'attente a bien été effectuée.
          </h3>
          <button class="fbutton" @click="backAccount">
            Retour à votre compte
          </button>
        </div>
      </div>
    </div>
    <dataform v-if="JSON.stringify(ctrldataform) != '{}'" :title="ctrldataform.title" :controls="ctrldataform.controls"
      :fxback="ctrldataform.fxback" :fxsave="ctrldataform.fxsave"></dataform>
    <loadingform v-if="loading"></loadingform>
    <v-snackbar v-model="error" :timeout="2000">
      Une erreur est survenue lors de la réservation, nous vous invitons à recommencer.
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import engine from "../core/DataAPI";
import login from "../core/IconLogin";
import dataform from "../core/DataForm";
import loadingform from "../core/LoadingForm";
import calendar from "../core/CalendarReservation.vue";

export default {
  components: {
    calendar,
    login,
    dataform,
    loadingform,
  },
  data() {
    return {
      error: false,
      rdvData: undefined,
      iban_information: undefined,
      loading: false,
      acceptcguv: false,
      msgtitle: "",
      msgsubtitle: "",
      msgretour: "",
      account: {},
      vdate: "",
      viewdiv: "reservation",
      maxstep: 5,
      currentstep: 1,
      piece_cni: false,
      piece_justificatif: false,
      piece_rib: false,
      piece_cmuc: false,
      piece_mobilite: false,
      piece_attestation: false,
      piece_cni_titulaire: false,
      step: 1,
      list_materials: [],
      list_agencies: [],
      list_prices: [],
      ctrldataform: {},
      banq_account: {
        nom: "",
        prenom: "",
        adresse: "",
        cp: "",
        ville: "",
        email: "",
        telephone: "",
        lien_parente: "",
        id_fichier: engine.Guid(),
      },
    };
  },
  watch: {
    step(val) {
      if (val === 5) {
        this.iban_verification();
      }
    },
  },
  computed: {
    is_valid_iban() {
      if (
        this.iban_information !== undefined &&
        this.iban_information.valid !== true
      )
        return false;
      return true;
    },
    visu_payment: function () {
      var tmain = this;
      var ret = false;
      console.log("visu_payment", tmain.account);

      if (tmain.step >= 5) {
        if (tmain.subscription_selected.id == 1) {
          if (
            tmain.piece_cni &&
            tmain.piece_justificatif &&
            tmain.piece_rib &&
            tmain.account.iban != "" &&
            // tmain.account.bic != "" &&
            tmain.is_valid_iban
          ) {
            if (tmain.account.is_main_bank == "oui") {
              ret = true;
            } else {
              if (
                tmain.banq_account.nom != "" &&
                tmain.piece_attestation &&
                tmain.piece_cni_titulaire
              ) {
                ret = true;
              }
            }
          }
        }
        if (tmain.subscription_selected.id == 2) {
          if (
            tmain.piece_cni &&
            tmain.piece_justificatif &&
            tmain.piece_rib &&
            tmain.piece_cmuc &&
            tmain.account.iban != ""
            //  &&
            // tmain.account.bic != ""
          ) {
            if (tmain.account.is_main_bank == "oui") {
              ret = true;
            } else {
              if (
                tmain.banq_account.nom != "" &&
                tmain.piece_attestation &&
                tmain.piece_cni_titulaire
              ) {
                ret = true;
              }
            }
          }
        }
        if (tmain.subscription_selected.id == 3) {
          if (
            tmain.piece_cni &&
            tmain.piece_justificatif &&
            tmain.piece_rib &&
            tmain.piece_mobilite &&
            tmain.account.iban != ""
            //  &&
            // tmain.account.bic != ""
          ) {
            if (tmain.account.is_main_bank == "oui") {
              ret = true;
            } else {
              if (
                tmain.banq_account.nom != "" &&
                tmain.piece_attestation &&
                tmain.piece_cni_titulaire
              ) {
                ret = true;
              }
            }
          }
        }
      }
      return ret;
    },
    material_selected: function () {
      var tmain = this;
      var info = tmain.list_materials.find((x) => x.selected == true);
      if (info == undefined) {
        info = {};
      }
      return info;
    },
    subscription_selected: function () {
      var tmain = this;
      var ret = {};
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        var infret = mat.subscriptions.find((x) => x.selected == true);
        if (infret !== undefined) {
          ret = infret;
        }
      }
      return ret;
    },
    price_selected: function () {
      var tmain = this;
      var ret = {};
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        var infsub = mat.subscriptions.find((x) => x.selected == true);
        if (infsub !== undefined) {
          var infprice = infsub.prices.find((x) => x.selected == true);
          if (infprice !== undefined) {
            ret = infprice;
          }
        }
      }
      return ret;
    },
    agency_selected: function () {
      var tmain = this;
      var info = tmain.list_agencies.find((x) => x.selected == true);
      if (info == undefined) {
        info = {};
      }
      return info;
    },
    lbl_description: function () {
      var tmain = this;
      var ret = "";
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        ret += "1 x " + mat.label + " - ";
        mat.accessories.forEach(function (acc) {
          if (acc.qties.find((x) => x.selected == true).value > 0) {
            ret +=
              acc.qties.find((x) => x.selected == true).value +
              " x " +
              acc.label +
              " - ";
          }
        });
      }
      return ret.substring(0, ret.length - 3);
    },
    lbl_amount: function () {
      var tmain = this;
      var ret = 0;
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        mat.accessories.forEach(function (acc) {
          ret += acc.amount * acc.qties.find((x) => x.selected == true).value;
        });
        var infsub = mat.subscriptions.find((x) => x.selected == true);
        if (infsub !== undefined) {
          var infprice = infsub.prices.find((x) => x.selected == true);
          if (infprice !== undefined) {
            ret += infprice.amount;
          }
        }
      }
      return ret;
    },
    lbl_amount_year: function () {
      var tmain = this;
      var ret = 0;
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        mat.accessories.forEach(function (acc) {
          ret += acc.amount * acc.qties.find((x) => x.selected == true).value;
        });
        var infsub = mat.subscriptions.find((x) => x.selected == true);
        if (infsub !== undefined) {
          var infprice = infsub.prices.find((x) => x.selected == true);
          if (infprice !== undefined) {
            ret += infprice.amount;
          }
        }
      }
      return ret * 11;
    },
    lbl_payment_info_cb: function () {
      var tmain = this;
      var lbl = "";
      var formatter = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      switch (tmain.price_selected.id) {
        case 1:
          lbl = formatter.format(tmain.lbl_amount) + " / mois";
          break;
        case 2:
          lbl =
            formatter.format(tmain.lbl_amount) +
            " d'acompte à régler maintenant, restant dû le jour de la réservation ";
          lbl +=
            formatter.format(tmain.lbl_amount * 10) +
            ". Par paiement CB uniquement.";
          break;
      }
      return lbl;
    },
  },
  mounted: function () {
    this.$moment.locale("fr");
  },
  created: function () {
    var tmain = this;
    if (JSON.stringify(tmain.$route.query) != "{}") {
      tmain.refresh_ret(tmain.$route.query);
    } else {
      tmain.refresh_ui();
    }
  },
  methods: {
    refresh_ret: function (params) {
      var tmain = this;
      tmain.loading = true;
      tmain.$router.replace({ query: null });
      tmain.viewdiv = "retour";
      tmain.msgretour = params.state;
      engine.Account_Info(function (pkg) {
        if (pkg.code == -1) {
          tmain.$router.push("/");
        } else {
          tmain.account = pkg.data[0];
          engine.Agency_List(function (pkga) {
            tmain.list_agencies = pkga.data;
            engine.Material_List(function (pkgm) {
              tmain.list_materials = pkgm.data;
              engine.File_Exist("cni", function (pkgp1) {
                tmain.piece_cni = pkgp1.data;
                engine.File_Exist("justificatif", function (pkgp2) {
                  tmain.piece_justificatif = pkgp2.data;
                  engine.File_Exist("rib", function (pkgp3) {
                    tmain.piece_rib = pkgp3.data;
                    engine.File_Exist("cmuc", function (pkgp4) {
                      tmain.piece_cmuc = pkgp4.data;
                      engine.File_Exist("passmobilite", function (pkgp5) {
                        tmain.piece_mobilite = pkgp5.data;
                        switch (params.state) {
                          case "effectue":
                            if (params.Erreur == "00000") {
                              tmain.msgtitle =
                                "Votre réservation " +
                                params.Ref +
                                " a été effectuée avec succès.";
                              // tmain.msgsubtitle =
                              //   "Un courriel vous a été envoyé afin que vous puissiez prendre RDV pour récupérer votre vélo.";
                              tmain.loading = false;
                            } else {
                              tmain.msgretour = "erreur";
                              tmain.msgtitle =
                                "Votre réservation " +
                                params.Ref +
                                " a rencontré une erreur.";
                              tmain.msgsubtitle =
                                "Veuillez contacter la RDT13 par e-mail au contact@leveloplus.com.";
                              tmain.loading = false;
                            }
                            break;
                          case "annule":
                            engine.Reservation_Cancel(params.Ref, function () {
                              tmain.msgtitle = "Annulation";
                              tmain.msgsubtitle =
                                "Vous avez annulé votre demande de réservation de vélo.";
                              tmain.loading = false;
                            });
                            break;
                          case "refuse":
                            tmain.msgtitle =
                              "Votre réservation " +
                              params.Ref +
                              " a rencontré une erreur de paiement.";
                            tmain.msgsubtitle =
                              "Veuillez contacter la RDT13 par e-mail au contact@leveloplus.com.";
                            tmain.loading = false;
                            break;
                        }
                      });
                    });
                  });
                });
              });
            });
          });
        }
      });
    },
    refresh_ui: function () {
      var tmain = this;
      tmain.loading = true;
      engine.Account_Info(function (pkg) {
        if (pkg.code != 0) {
          tmain.$router.push("/");
        } else {
          tmain.account = pkg.data[0];
          engine.Agency_List(function (pkga) {
            tmain.list_agencies = pkga.data;
            engine.Material_List(function (pkgm) {
              tmain.list_materials = pkgm.data;
              tmain.loading = false;
              if (
                tmain.list_materials[0].qty <= 0 &&
                tmain.list_materials[1].qty <= 0 &&
                tmain.list_materials[2].qty <= 0
              ) {
                tmain.viewdiv = "nostock";
              }
            });
          });
        }
        engine.File_Exist("cni", function (pkgp1) {
          tmain.piece_cni = pkgp1.data;
        });
        engine.File_Exist("justificatif", function (pkgp2) {
          tmain.piece_justificatif = pkgp2.data;
        });
        engine.File_Exist("rib", function (pkgp3) {
          tmain.piece_rib = pkgp3.data;
        });
        engine.File_Exist("cmuc", function (pkgp4) {
          tmain.piece_cmuc = pkgp4.data;
        });
        engine.File_Exist("passmobilite", function (pkgp5) {
          tmain.piece_mobilite = pkgp5.data;
        });
      });
    },
    refresh_docs: function () {
      console.log("refresh_docs");
      var tmain = this;
      engine.File_Exist("cni", function (pkgp1) {
        tmain.piece_cni = pkgp1.data;
        engine.File_Exist("cni_titulaire", function (pkgp12) {
          tmain.piece_cni_titulaire = pkgp12.data;
          engine.File_Exist("justificatif", function (pkgp2) {
            tmain.piece_justificatif = pkgp2.data;
            engine.File_Exist("rib", function (pkgp3) {
              tmain.piece_rib = pkgp3.data;
              engine.File_Exist("cmuc", function (pkgp4) {
                tmain.piece_cmuc = pkgp4.data;
                engine.File_Exist("passmobilite", function (pkgp5) {
                  tmain.piece_mobilite = pkgp5.data;
                  engine.File_Exist(
                    tmain.banq_account.id_fichier,
                    function (pkgp6) {
                      tmain.piece_attestation = pkgp6.data;
                      var options = {
                        container: ".application",
                        easing: "ease-out",
                        offset: -10,
                        force: true,
                        cancelable: true,
                        x: false,
                        y: true,
                      };
                      if (tmain.account.is_main_bank == "non") {
                        if (
                          tmain.piece_attestation &&
                          tmain.piece_cni_titulaire
                        ) {
                          setTimeout(function () {
                            tmain.$scrollTo("#bloc_recap", 500, options);
                          }, 100);
                        } else {
                          setTimeout(function () {
                            tmain.$scrollTo("#bloc6", 500, options);
                          }, 100);
                        }
                      } else {
                        setTimeout(function () {
                          tmain.$scrollTo("#bloc_recap", 500, options);
                        }, 100);
                      }
                    }
                  );
                });
              });
            });
          });
        });
      });
    },
    validS1: function (mat) {
      var tmain = this;

      if (mat.qty > 0) {
        tmain.list_materials.forEach((mat) => {
          mat.selected = false;
          mat.accessories.forEach((acc) => {
            acc.selected = false;
            acc.qties.forEach((q) => (q.selected = false));
            //selection automatique des accessoires
            acc.qties[0].selected = true;
          });
        });
        tmain.list_agencies.forEach((x) => (x.selected = false));
        tmain.qtycumul = 0;
        mat.selected = true;
        var options = {
          container: ".application",
          easing: "ease-out",
          offset: -10,
          force: true,
          cancelable: true,
          x: false,
          y: true,
        };

        if (mat.id == 2) {
          tmain.step = 2;
          setTimeout(function () {
            tmain.$scrollTo("#bloc2", 500, options);
          }, 100);
        } else {
          //selection automatique des accessoires
          //tmain.step = 1;
          tmain.step = 2;

          setTimeout(function () {
            tmain.$scrollTo("#blocacc", 500, options);
          }, 100);
        }
      }
    },
    validS1acc: function (acc, qtyselect) {
      var tmain = this;
      acc.qties.forEach((x) => (x.selected = false));

      acc.selected = true;
      qtyselect.selected = true;
      var qtycumul = 0;
      tmain.material_selected.accessories
        .filter((x) => x.selected == true)
        .forEach(function (tacc) {
          tacc.qties
            .filter((q) => q.selected == true)
            .forEach(function (qt) {
              qtycumul += qt.value;
            });
        });

      if (
        tmain.material_selected.accessories.filter((x) => x.selected == true)
          .length == tmain.material_selected.accessories.length
      ) {
        if (qtycumul > tmain.material_selected.qtymaxaccessories) {
          qtyselect.selected = false;
          tmain.material_selected.accessories
            .filter((x) => x.selected == true)
            .forEach(function (tacc) {
              if (tacc.qties.filter((q) => q.selected == true).length == 0) {
                tacc.selected = false;
                tmain.step = 1;
              }
            });
          alert("trop de qte selectionné");
        } else {
          tmain.step = 2;
          var options = {
            container: ".application",
            easing: "ease-out",
            offset: -10,
            force: true,
            cancelable: true,
            x: false,
            y: true,
          };
          setTimeout(function () {
            tmain.$scrollTo("#bloc2", 500, options);
          }, 100);
        }
      }
    },
    validS2: function (ag) {
      var tmain = this;
      tmain.step = 2;
      tmain.list_agencies.forEach((x) => (x.selected = false));
      ag.selected = true;
      var options = {
        container: ".application",
        easing: "ease-out",
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      setTimeout(function () {
        tmain.$scrollTo("#calendar", 500, options);
      }, 100);
    },
    validRdvS2(event) {
      var tmain = this;
      tmain.rdvData = event["Clé primaire"];
      tmain.step = 3;
      var options = {
        container: ".application",
        easing: "ease-out",
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      setTimeout(function () {
        tmain.$scrollTo("#bloc3", 500, options);
      }, 100);
    },
    validS3: function (subs) {
      var tmain = this;
      tmain.material_selected.subscriptions.forEach(
        (s) => (s.selected = false)
      );
      subs.selected = true;
      tmain.step = 4;

      tmain.list_prices = [];
      var ret = 0;
      var mat = tmain.list_materials.find((x) => x.selected == true);
      if (mat !== undefined) {
        mat.accessories.forEach(function (acc) {
          ret += acc.amount * acc.qties.find((x) => x.selected == true).value;
        });
        ret += subs.prices[0].amount;
      }

      tmain.list_prices.push({ amount: ret });
      tmain.list_prices.push({ amount: ret * 11 });

      var options = {
        container: ".application",
        easing: "ease-out",
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      setTimeout(function () {
        tmain.$scrollTo("#bloc4", 500, options);
      }, 100);
    },
    validS4: function (p) {
      var tmain = this;
      tmain.subscription_selected.prices.forEach((s) => (s.selected = false));
      p.selected = true;
      tmain.step = 5;
      var options = {
        container: ".application",
        easing: "ease-out",
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      };
      setTimeout(function () {
        tmain.$scrollTo("#bloc5", 500, options);
      }, 100);
    },
    editprofile: function () {
      var tmain = this;
      tmain.ctrldataform = {
        title: "Modifier vos informations personnelles",
        controls: [
          {
            datatype: "text",
            datavalue: tmain.account.iban,
            databind: "iban",
            description: "IBAN (la longueur doit être de 27 caractères)",
          },
          {
            datatype: "text",
            datavalue: tmain.account.bic,
            databind: "bic",
            description: "BIC",
            display: "none",
          },
          {
            datatype: "select",
            datavalue: tmain.account.is_main_bank,
            databind: "is_main_bank",
            description: "Etes-vous titulaire du compte bancaire ?",
            datalist: [
              { value: "oui", label: "Oui" },
              { value: "non", label: "Non" },
            ],
          },
        ],
        fxback: function () {
          tmain.ctrldataform = {};
        },
        fxsave: function (o) {
          if (o.iban) {
            tmain.account.iban = o.iban;
            let iban = o.iban.replace(/ /g, "").toUpperCase();
            let fourFistDigit = iban.substring(0, 4);
            let newIban = iban.substring(4, iban.length) + fourFistDigit;
            let ibanWithoutLetters = "";
            for (let i = 0; i < newIban.length; i++) {
              if (
                newIban[i].charCodeAt(0) >= 65 &&
                newIban[i].charCodeAt(0) <= 90
              ) {
                ibanWithoutLetters += newIban[i].charCodeAt(0) - 55;
              } else {
                ibanWithoutLetters += newIban[i];
              }
            }
            if (tmain.checkIBAN(ibanWithoutLetters) == 1) {
              tmain.iban_verification(
                function (newIbanData) {
                  engine.Account_Edit_Bank(newIbanData, function () {
                    engine.Account_Info(function (pkg) {
                      if (pkg.code == -1) {
                        tmain.$router.push("/");
                      } else {
                        // tmain.account = pkg.data[0];
                        // tmain.iban_verification();
                        tmain.ctrldataform = {};
                        tmain.refresh_docs();
                      }
                    });
                  });
                  tmain.ctrldataform = {};
                }
              );
            } else {
              tmain.iban_information = { valid: false };
              engine.Account_Edit_Bank({ iban: o.iban, bic: "" }, function () {
                engine.Account_Info(function (pkg) {
                  if (pkg.code == -1) {
                    tmain.$router.push("/");
                  } else {
                    tmain.account = pkg.data[0];
                    tmain.refresh_docs();
                    tmain.ctrldataform = {};
                  }
                });
              })
            }
            // });
          }
          else {
            tmain.iban_information = { valid: false };
            engine.Account_Edit_Bank({ iban: o.iban, bic: "" }, function () {
              engine.Account_Info(function (pkg) {
                if (pkg.code == -1) {
                  tmain.$router.push("/");
                } else {
                  tmain.account = pkg.data[0];
                  tmain.refresh_docs();
                  tmain.ctrldataform = {};
                }
              });
            })
          }
          // }
          // tmain.refresh_docs();
          // tmain.ctrldataform = {};
        }
      };
    },
    iban_verification(callback) {
      var tmain = this;
      if (tmain.account.iban) {
        tmain.iban_information = { valid: false };
        let iban = tmain.account.iban.replace(/ /g, "").toUpperCase();
        let fourFistDigit = iban.substring(0, 4);
        let newIban = iban.substring(4, iban.length) + fourFistDigit;
        let ibanWithoutLetters = "";
        for (let i = 0; i < newIban.length; i++) {
          if (
            newIban[i].charCodeAt(0) >= 65 &&
            newIban[i].charCodeAt(0) <= 90
          ) {
            ibanWithoutLetters += newIban[i].charCodeAt(0) - 55;
          } else {
            ibanWithoutLetters += newIban[i];
          }
        }
        if (tmain.checkIBAN(ibanWithoutLetters) === 1) {
          engine.Iban_verification(iban, function (data) {
            console.log("Iban_verification", data);
            let bic = ""
            if (data.code == 0) {
              console.log(0);
              if (data.data.bic) {
                console.log(1);
                bic = data.data.bic
                tmain.account.bic = data.data.bic;
              }
              tmain.iban_information = {
                iban: iban,
                bic: bic,
                valid: true,
              };
            } else {
              console.log(2);
              tmain.iban_information = { valid: false };
              tmain.account.bic = "";
            }
            tmain.ctrldataform = {};
            tmain.refresh_docs();
            if (callback) callback({ iban, bic })
          })
        } else {
          tmain.iban_information = { valid: false };
          tmain.account.bic = "";
          if (callback) callback({ iban, bic: "" })
        }
      }
    },
    checkIBAN(iban) {
      const parts = iban.match(/.{1,6}/g);
      return parts.reduce((prev, curr) => Number(prev + curr) % 97, "");
    },
    editbanqaccount: function () {
      var tmain = this;
      tmain.ctrldataform = {
        title: "Indiquer les informations du titulaire du compte bancaire",
        controls: [
          {
            datatype: "text",
            datavalue: tmain.banq_account.prenom,
            databind: "prenom",
            description: "Prénom *",
            condvalue: false,
            condmsg: "Le prénom est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.nom,
            databind: "nom",
            description: "Nom de famille *",
            condvalue: false,
            condmsg: "Le nom de famille est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.adresse,
            databind: "adresse",
            description: "Adresse *",
            condvalue: false,
            condmsg: "L'adresse est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.cp,
            databind: "cp",
            description: "Code postal *",
            condvalue: false,
            condmsg: "Le code postal est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.ville,
            databind: "ville",
            description: "Ville *",
            condvalue: false,
            condmsg: "La ville est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.telephone,
            databind: "telephone",
            description: "Téléphone fixe *",
            condvalue: false,
            condmsg: "Le numéro de téléphone est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.email,
            databind: "email",
            description: "Courriel *",
            condvalue: false,
            condmsg: "Le courriel est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
          {
            datatype: "text",
            datavalue: tmain.banq_account.lien_parente,
            databind: "lien_parente",
            description: "Lien de parenté *",
            condvalue: false,
            condmsg: "Le lien de parenté est obligatoire",
            condfx: function (val) {
              return val != "";
            },
          },
        ],
        fxback: function () {
          tmain.ctrldataform = {};
        },
        fxsave: function (o) {
          tmain.banq_account.prenom = o.prenom;
          tmain.banq_account.nom = o.nom;
          tmain.banq_account.adresse = o.adresse;
          tmain.banq_account.cp = o.cp;
          tmain.banq_account.ville = o.ville;
          tmain.banq_account.telephone = o.telephone;
          tmain.banq_account.email = o.email;
          tmain.banq_account.lien_parente = o.lien_parente;
          tmain.refresh_docs();
          tmain.ctrldataform = {};
        },
      };
    },
    editbanqfile: function () {
      var tmain = this;
      tmain.account.file_attestation = "";
      tmain.ctrldataform = {
        title: "Fournir le formulaire de procuration",
        controls: [
          {
            datatype: "link",
            description:
              "Etape 1 : Télécharger le formulaire vierge de procuration",
            link: "https://reservation.leveloplus.com/media/procuration.doc",
          },
          {
            datatype: "file",
            databind: "file_attestation",
            description:
              "Etape 2 : Envoyer le formulaire de procuration complété et signé",
            condvalue: false,
            condmsg: "Le fichier doit être au format PDF ou image",
            condfx: function (val) {
              return (
                val.type == "application/pdf" ||
                val.type.indexOf("png") > -1 ||
                val.type.indexOf("jpg") > -1 ||
                val.type.indexOf("jpeg") > -1
              );
            },
          },
        ],
        fxback: function () {
          tmain.ctrldataform = {};
        },
        fxsave: function (ctrl) {
          engine.File_Upload(
            ctrl.file_attestation,
            tmain.banq_account.id_fichier,
            function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            }
          );
        },
      };
    },
    downloadFile: function (doctype) {
      var tmain = this;
      if (doctype == "attestation") {
        doctype = tmain.banq_account.id_fichier;
      }
      engine.File_Download(doctype, function (pkgfile) {
        var ctype = pkgfile.headers.map["content-type"];
        var extname = ".pdf";
        if (ctype[0] == "image/png") {
          extname = ".png";
        }
        if (ctype[0] == "image/jpg") {
          extname = ".jpg";
        }
        if (ctype[0] == "image/jpeg") {
          extname = ".jpeg";
        }
        var fileURL = window.URL.createObjectURL(new Blob([pkgfile.data]));
        var download_link = document.createElement("a");
        download_link.href = fileURL;
        download_link.download = doctype + extname;
        download_link.target = "_blank";
        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);
      });
    },
    uploadFile: function (doctype) {
      var tmain = this;
      tmain.account.file_cni = "";
      tmain.account.file_cni_titulaire = "";
      tmain.account.file_justificatif = "";
      tmain.account.file_rib = "";
      tmain.account.file_cmuc = "";
      tmain.account.file_passmobilite = "";
      tmain.account.file_attestation = "";

      if (doctype == "cni") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_cni",
              description: "CNI / Passeport (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(ctrl.file_cni, "cni", function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            });
          },
        };
      }
      if (doctype == "cni_titulaire") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_cni_titulaire",
              description: "CNI / Passeport (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(
              ctrl.file_cni_titulaire,
              "cni_titulaire",
              function () {
                tmain.ctrldataform = {};
                tmain.refresh_docs();
              }
            );
          },
        };
      }
      if (doctype == "justificatif") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_justificatif",
              description: "Justificatif de domicile (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(
              ctrl.file_justificatif,
              "justificatif",
              function () {
                tmain.ctrldataform = {};
                tmain.refresh_docs();
              }
            );
          },
        };
      }
      if (doctype == "rib") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_rib",
              description:
                "Relevé d'Identité Bancaire (RIB - au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(ctrl.file_rib, "rib", function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            });
          },
        };
      }
      if (doctype == "cmuc") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_cmuc",
              description: "Attestation CMUC (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(ctrl.file_cmuc, "cmuc", function () {
              tmain.ctrldataform = {};
              tmain.refresh_docs();
            });
          },
        };
      }
      if (doctype == "passmobilite") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "html",
              description:
                "<p style=\"border:solid 2px red;padding:5px;\"><strong>Comment savoir si je peux vénéficier de ce tarif réduit ?</strong><br>Vous pouvez bénéficier de ce tarif si vous détenez un abonnement annuel et payant à l'un de ces réseaux de transports en commun de la Métropole Mobilité :<br>- le<strong>car</strong> (Pays d'Aix Mobilité, Cartreize, Marcouline)<br>- le<strong>bus</strong>/le <strong>tram</strong>/le<strong>métro</strong>  (Aix en Bus,  Ciotabus,  RTM, Salon Etang Côte bleue,  Ulysse),  <br>- la<strong>navette</strong> et le<strong>bateau</strong><br>- Sont également éligibles : le <strong>pass Intégral ou Métropole annuel ou  permanent</strong> (annuel  avec paiement mensualisé)<br><br>En cas de doute, veuillez demander à contact@leveloplus.com<br><br><strong>Comment obtenir un justificatif d'abonnement ?</strong><br>Veuillez-vous rendre en boutique de votre réseau de transport.</p>",
            },
            {
              datatype: "file",
              databind: "file_passmobilite",
              description:
                "Attestation Abonnement Intégral Annuel ou Pass Métropole (au format PDF ou image)",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(
              ctrl.file_passmobilite,
              "passmobilite",
              function () {
                tmain.ctrldataform = {};
                tmain.refresh_docs();
              }
            );
          },
        };
      }
      if (doctype == "attestation") {
        tmain.ctrldataform = {
          title: "Envoyer des pièces justificatives",
          controls: [
            {
              datatype: "file",
              databind: "file_attestation",
              description: "Procuration du titulaire du compte bancaire",
              condvalue: false,
              condmsg:
                "Le fichier doit être au format PDF ou image avec une taille < 5 Mo",
              condfx: function (val) {
                return (
                  val.size < 5242880 &&
                  (val.type == "application/pdf" ||
                    val.type.indexOf("png") > -1 ||
                    val.type.indexOf("jpg") > -1 ||
                    val.type.indexOf("jpeg") > -1)
                );
              },
            },
          ],
          fxback: function () {
            tmain.ctrldataform = {};
          },
          fxsave: function (ctrl) {
            engine.File_Upload(
              ctrl.file_attestation,
              tmain.banq_account.id_fichier,
              function () {
                tmain.ctrldataform = {};
                tmain.refresh_docs();
              }
            );
          },
        };
      }
    },
    payment_start: function () {
      var tmain = this;
      var pkgp = {
        materials: tmain.list_materials,
        agencies: tmain.list_agencies,
        prices: tmain.list_prices,
        options: {
          is_main_bank: tmain.account.is_main_bank,
          banq_account: tmain.banq_account,
          planning_rdv_key: tmain.rdvData,
        },
      };
      engine.Reservation_Create(pkgp, function (pkgcreate) {

        if (pkgcreate.code == 0) {
          var datenow = tmain.$moment().format();
          var amount = tmain.lbl_amount * 100;
          var reference = pkgcreate.data;
          //var reference = 'RSW-TEST01-'+tmain.$moment().format('HHmm');
          var mail = tmain.account.email;
          var paquet = [
            { label: "PBX_TOTAL", value: amount },
            { label: "PBX_DEVISE", value: "978" },
            { label: "PBX_CMD", value: reference },
            { label: "PBX_SOURCE", value: "RWD" },
            { label: "PBX_PORTEUR", value: mail },
            { label: "PBX_RETOUR", value: "Mt:M;Ref:R;Auto:A;Erreur:E" },
            // {
            //   label: "PBX_REPONDRE_A",
            //   value:
            //     "https://rdt13.accen.me/api/v1/reservation/payment",
            // },
            // {
            //   label: "PBX_EFFECTUE",
            //   value:
            //     "https://rdt13.accen.me/#/reservation?state=effectue",
            // },
            // {
            //   label: "PBX_REFUSE",
            //   value:
            //     "https://rdt13.accen.me/#/reservation?state=refuse",
            // },
            // {
            //   label: "PBX_ANNULE",
            //   value:
            //     "https://rdt13.accen.me/#/reservation?state=annule",
            // },
            {
              label: "PBX_REPONDRE_A",
              value:
                "https://reservation.leveloplus.com/api/v1/reservation/payment",
            },
            {
              label: "PBX_EFFECTUE",
              value:
                "https://reservation.leveloplus.com/#/reservation?state=effectue",
            },
            {
              label: "PBX_REFUSE",
              value:
                "https://reservation.leveloplus.com/#/reservation?state=refuse",
            },
            {
              label: "PBX_ANNULE",
              value:
                "https://reservation.leveloplus.com/#/reservation?state=annule",
            },
            { label: "PBX_HASH", value: "SHA512" },
            { label: "PBX_TIME", value: datenow },
            {
              label: "PBX_BILLING", value:
                '<Billing><Address> <FirstName>'
                + tmain.account.firstname +
                '</FirstName> <LastName>'
                + tmain.account.lastname +
                '</LastName> <Address1>'
                + tmain.account.address +
                '</Address1> <ZipCode>'
                + tmain.account.zipcode +
                '</ZipCode> <City>' +
                tmain.account.city + '</City> <CountryCode>250</CountryCode> </Address> </Billing>'
            },
            {
              label: "PBX_SHOPPINGCART", value: "<shoppingcart><total><totalQuantity>01</totalQuantity></total></shoppingcart>"
            }
          ];

          engine.GetHmac(paquet, function (pkg) {
            if (pkg.code == 0) {
              var form = document.createElement("form");
              form.setAttribute("method", "post");
              form.setAttribute("action", pkg.data.website);
              form.setAttribute("target", "");
              tmain.createHiddenInput(form, "PBX_SITE", pkg.data.site);
              tmain.createHiddenInput(form, "PBX_RANG", pkg.data.rang);
              tmain.createHiddenInput(
                form,
                "PBX_IDENTIFIANT",
                pkg.data.identifiant
              );
              for (var i = 0; i < paquet.length; i++) {
                tmain.createHiddenInput(form, paquet[i].label, paquet[i].value);
              }
              tmain.createHiddenInput(form, "PBX_HMAC", pkg.data.hmac);
              document.body.appendChild(form);
              form.submit();
            }
          });
        } else {
          tmain.rdvData = undefined
          tmain.step = 1
          tmain.error = true
        }
      });
    },
    createHiddenInput: function (form, label, value) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", label);
      hiddenField.setAttribute("value", value);
      form.appendChild(hiddenField);
    },
    backAccount: function () {
      var tmain = this;
      tmain.$router.push("/board");
    },
    goRDV: function () {
      var download_link = document.createElement("a");
      download_link.href =
        "https://www.clicrdv.com/le-velo-plus?intervention_ids[]=3307709";
      download_link.target = "_blank";
      document.body.appendChild(download_link);
      download_link.click();
      document.body.removeChild(download_link);
    },
    goNOSTOCK: function () {
      var tmain = this;
      engine.Reservation_Nostock(function () {
        tmain.viewdiv = "nostock_valid";
      });
    },
  },
};
</script>
<style></style>
