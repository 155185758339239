<template>
  <div>
    <div class="application">
      <div class="line showpc" style="height:60px;border-bottom:solid 1px var(--C3);">
        <div class="line-33">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:left;margin-left:15px;">
            <li style="line-height:60px;">
              <img src="../assets/rdt13.png" alt="" style="height:50px;margin-top:5px;">
            </li>
          </ul>
        </div>
        <div class="line-34">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:center;">
            <li style="line-height:60px;">
              <img src="../assets/logo.png" alt="" style="cursor:pointer;height:50px;margin-top:5px;" @click="navigation('board')">
            </li>
          </ul>
        </div>
        <div class="line-33">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:right;margin-right:15px;">
            <li style="line-height:60px;">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div class="line showmobile" style="height:60px;border-bottom:solid 1px var(--C3);">
        <div class="line-50">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:left;margin-left:15px;">
            <li style="line-height:60px;">
              <img src="../assets/logo.png" alt="" style="height:50px;margin-top:5px;">
            </li>
          </ul>
        </div>
        <div class="line-50">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:right;margin-right:15px;">
            <li style="line-height:60px;">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div class="line" style="text-align:center;">      
        <h1>Informations personnelles</h1>
        <h3>Informations nécessaires utilisées pour la réservation en ligne</h3>
      </div>
      <div class="line-a" style="margin-top:40px;">
        <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size:18px;">Profil</label>
              <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;">Modifier vos informations personnelles</p>
            </div>
            <div class="account-bloc-text">
              <div class="account-bloc-legend">code client</div>
              <div class="account-bloc-value" style="text-transform:capitalize;">{{account.code}}</div>
              <!-- <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div> -->
              <div class="account-bloc-icon"></div>
            </div>
            <div class="account-bloc-text">
              <div class="account-bloc-legend">nom complet</div>
              <div class="account-bloc-value" style="text-transform:capitalize;">{{account.fullname}}</div>
              <!-- <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div> -->
              <div class="account-bloc-icon"></div>
            </div>
            <div class="account-bloc-text">
              <div class="account-bloc-legend">adresse</div>
              <div class="account-bloc-value">{{account.address}} {{account.zipcode}} {{account.city}}</div>
              <!-- <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div> -->
              <div class="account-bloc-icon"></div>
            </div>
            <div class="account-bloc-text">
              <div class="account-bloc-legend">téléphone</div>
              <div class="account-bloc-value">{{account.phone}}</div>
              <!-- <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div> -->
              <div class="account-bloc-icon"></div>
            </div>
            <div class="account-bloc-text">
              <div class="account-bloc-legend">mobile</div>
              <div class="account-bloc-value">{{account.mobile}}</div>
              <!-- <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div> -->
              <div class="account-bloc-icon"></div>
            </div>
            <div class="account-bloc-text">
              <div class="account-bloc-legend">date de naissance</div>
              <div class="account-bloc-value">{{account.birthday | moment('DD MMMM YYYY')}}</div>
              <!-- <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div> -->
              <div class="account-bloc-icon"></div>
            </div>
            <div class="account-bloc-text">
              <div class="account-bloc-legend">iban / bic</div>
              <div class="account-bloc-value">{{account.iban}} / {{account.bic}}</div>
              <!-- <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div> -->
              <div class="account-bloc-icon"></div>
            </div>
            <div class="account-bloc-text" @click="editprofile">
              <div class="account-bloc-legend" style="color:var(--BUTTON);">modifier</div>
              <div class="account-bloc-value" style="color:var(--BUTTON);font-weight:bold;">Modifier les informations personnelles</div>
              <div class="account-bloc-icon"><i class='fa fa-chevron-right' style="color:var(--BUTTON);"></i></div>
            </div>
        </div>      
      </div>
      <div class="line-a" style="margin-top:40px;">
        <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size:18px;">Documents</label>
              <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;">Gérer vos documents adminitratifs</p>
            </div>
            <div v-if="piece_cni==true" class="account-bloc-text">
              <div class="account-bloc-legend">CNI / Passport</div>
              <div class="account-bloc-value" @click="downloadFile('cni')">Consulter le document</div>
              <div class="account-bloc-icon"  @click="uploadFile('cni')"><i class='fa fa-upload'></i></div>
            </div>
            <div v-if="piece_cni==false" class="account-bloc-text" @click="uploadFile('cni')">
              <div class="account-bloc-legend">CNI / Passport</div>
              <div class="account-bloc-value" style="color:red;font-weight:bold;"><i class='fa fa-info-circle'></i> Document manquant</div>
              <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
            </div>
            <div v-if="piece_justificatif==true" class="account-bloc-text">
              <div class="account-bloc-legend">Justificatif de domicile</div>
              <div class="account-bloc-value" @click="downloadFile('justificatif')">Consulter le document</div>
              <div class="account-bloc-icon"  @click="uploadFile('justificatif')"><i class='fa fa-upload'></i></div>
            </div>
            <div v-if="piece_justificatif==false" class="account-bloc-text" @click="uploadFile('justificatif')">
              <div class="account-bloc-legend">Justificatif de domicile</div>
              <div class="account-bloc-value" style="color:red;font-weight:bold;"><i class='fa fa-info-circle'></i> Document manquant</div>
              <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
            </div>
            <div v-if="piece_rib==true" class="account-bloc-text">
              <div class="account-bloc-legend">RIB</div>
              <div class="account-bloc-value" @click="downloadFile('rib')">Consulter le document</div>
              <div class="account-bloc-icon"  @click="uploadFile('rib')"><i class='fa fa-upload'></i></div>
            </div>
            <div v-if="piece_rib==false" class="account-bloc-text" @click="uploadFile('rib')">
              <div class="account-bloc-legend">RIB</div>
              <div class="account-bloc-value" style="color:red;font-weight:bold;"><i class='fa fa-info-circle'></i> Document manquant</div>
              <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
            </div>
            <div v-if="piece_cmuc==true" class="account-bloc-text">
              <div class="account-bloc-legend">Attestation CMUC</div>
              <div class="account-bloc-value" @click="downloadFile('cmuc')">Consulter le document</div>
              <div class="account-bloc-icon"  @click="uploadFile('cmuc')"><i class='fa fa-upload'></i></div>
            </div>
            <div v-if="piece_cmuc==false" class="account-bloc-text" @click="uploadFile('cmuc')">
              <div class="account-bloc-legend">Attestation CMUC</div>
              <div class="account-bloc-value" style="color:red;font-weight:bold;"><i class='fa fa-info-circle'></i> Document manquant</div>
              <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
            </div>
            <div v-if="piece_pass==true" class="account-bloc-text">
              <div class="account-bloc-legend">Attestation Pass Mobilités</div>
              <div class="account-bloc-value" @click="downloadFile('passmobilite')">Consulter le document</div>
              <div class="account-bloc-icon"  @click="uploadFile('passmobilite')"><i class='fa fa-upload'></i></div>
            </div>
            <div v-if="piece_pass==false" class="account-bloc-text" @click="uploadFile('passmobilite')">
              <div class="account-bloc-legend">Attestation Pass Mobilités</div>
              <div class="account-bloc-value" style="color:red;font-weight:bold;"><i class='fa fa-info-circle'></i> Document manquant</div>
              <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
            </div>
        </div>      
      </div>
      <div class="line-a" style="margin-top:40px;margin-bottom:40px;">
        <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size:18px;">Actions</label>
              <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;">Gérer vos informations personnelles</p>
            </div>
            <div class="account-bloc-text" @click="account_inactive">
              <div class="account-bloc-legend">Désactiver</div>
              <div class="account-bloc-value">Désactiver mon compte en ligne</div>
              <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
            </div>
            <div class="account-bloc-text" @click="account_delete">
              <div class="account-bloc-legend">Supprimer</div>
              <div class="account-bloc-value"> Supprimer mes données personnelles conformément à la RGPD</div>
              <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
            </div>
        </div>      
      </div>
    </div>
    <dataform v-if="JSON.stringify(ctrldataform)!='{}'" :title="ctrldataform.title" :controls="ctrldataform.controls" :fxback="ctrldataform.fxback" :fxsave="ctrldataform.fxsave"></dataform>
  </div>
</template>

<script>
import engine from "../core/DataAPI";
import login from "../core/IconLogin";
import dataform from "../core/DataForm";

export default {
  components:{
    login,dataform
  },
  data() {
    return {
      account:{},
      vdate:'',
      piece_cni:false,
      piece_justificatif:false,
      piece_rib:false,
      piece_cmuc:false,
      piece_pass:false,
      ctrldataform: {}
    }
  },
  mounted: function(){
    this.$moment.locale('fr');
  },
  created: function(){
    var tmain = this;
    tmain.refresh_ui();
  },
  methods: {
    refresh_ui: function(){
      var tmain = this;
      engine.Account_Info(function(pkg){
        if (pkg.code!=0){
          tmain.$router.push('/');
        }else{
          tmain.account = pkg.data[0];
        }
        engine.File_Exist('cni',function(pkgp1){
          tmain.piece_cni = pkgp1.data;
        });
        engine.File_Exist('justificatif',function(pkgp2){
          tmain.piece_justificatif = pkgp2.data;
        });
        engine.File_Exist('rib',function(pkgp3){
          tmain.piece_rib = pkgp3.data;
        });
        engine.File_Exist('cmuc',function(pkgp4){
          tmain.piece_cmuc = pkgp4.data;
        });
        engine.File_Exist('passmobilite',function(pkgp5){
          tmain.piece_pass = pkgp5.data;
        });
      });
    },
    editprofile: function(){
      var tmain = this;
      tmain.ctrldataform = { 
        title:"Modifier vos informations personnelles",
        controls:[
          // {datatype:'text',datavalue:tmain.account.firstname,databind:'firstname',description:'Prénom *',condvalue:false,condmsg:'Le prénom est obligatoire',condfx:function(val){return (val!=""); }},
          // {datatype:'text',datavalue:tmain.account.lastname,databind:'lastname',description:'Nom de famille *',condvalue:false,condmsg:'Le nom de famille est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'text',datavalue:tmain.account.address,databind:'address',description:'Adresse *',condvalue:false,condmsg:'L\'adresse est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'text',datavalue:tmain.account.address2,databind:'address2',description:'Adresse complémentaire'},
          {datatype:'text',datavalue:tmain.account.zipcode,databind:'zipcode',description:'Code postal *',condvalue:false,condmsg:'Le code postal est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'text',datavalue:tmain.account.city,databind:'city',description:'Ville *',condvalue:false,condmsg:'La ville est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'text',datavalue:tmain.account.phone,databind:'phone',description:'Téléphone fixe *',condvalue:false,condmsg:'Le numéro de téléphone fixe est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'text',datavalue:tmain.account.mobile,databind:'mobile',description:'Mobile *',condvalue:false,condmsg:'Le numéro de téléphone mobile est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'date',datavalue:tmain.account.birthday,databind:'birthday',description:'Date de naissance'},
          // {datatype:'text',datavalue:tmain.account.iban,databind:'iban',description:'Iban'},
          // {datatype:'text',datavalue:tmain.account.bic,databind:'bic',description:'Bic'}
        ],
        fxback:function(){
          tmain.ctrldataform={};
        },
        fxsave:function(o){
          
          engine.Account_Edit(o,function(){
            tmain.refresh_ui();
          });
          tmain.ctrldataform={};
        }
      };
    },
    downloadFile:function(doctype){
      engine.File_Download(doctype,function(pkgfile){
        var ctype = pkgfile.headers.map["content-type"];
        var extname = ".pdf";
        if (ctype[0]=="image/png"){extname=".png";}
        if (ctype[0]=="image/jpg"){extname=".jpg";}
        if (ctype[0]=="image/jpeg"){extname=".jpeg";}
        var fileURL = window.URL.createObjectURL(new Blob([pkgfile.data]));
        var download_link = document.createElement('a');
        download_link.href = fileURL;
        download_link.download = doctype + extname;
        download_link.target = "_blank";
        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);
      });
    },
    uploadFile: function(doctype){
      var tmain = this;
      tmain.account.file_justificatif = "";
      tmain.account.file_cni = "";
      tmain.account.file_rib = "";
      tmain.account.file_cmuc = "";
      tmain.account.file_passmobilite = "";
      if (doctype=="cni"){
        tmain.ctrldataform = { 
          title:"Envoyer des pièces justificatives",
          controls:[
            {datatype:'file',databind:'file_cni',description:'CNI / Passeport (au format PDF ou image)',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image avec une taille < 5 Mo',condfx:function(val){return (val.size < 5242880 && (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1)); }}
          ],
          fxback:function(){
            tmain.ctrldataform={};
          },
          fxsave:function(ctrl){
            engine.File_Upload(ctrl.file_cni,'cni',function(){
              tmain.ctrldataform={};
              tmain.refresh_ui();
            });          
          }
        };
      }
      if (doctype=="justificatif"){
        tmain.ctrldataform = { 
          title:"Envoyer des pièces justificatives",
          controls:[
            {datatype:'file',databind:'file_justificatif',description:'Justificatif de domicile (au format PDF ou image)',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image avec une taille < 5 Mo',condfx:function(val){return (val.size < 5242880 && (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1)); }}
          ],
          fxback:function(){
            tmain.ctrldataform={};
          },
          fxsave:function(ctrl){
            engine.File_Upload(ctrl.file_justificatif,'justificatif',function(){
              tmain.ctrldataform={};
              tmain.refresh_ui();
            });          
          }
        };
      }
      if (doctype=="rib"){
        tmain.ctrldataform = { 
          title:"Envoyer des pièces justificatives",
          controls:[
            {datatype:'file',databind:'file_rib',description:'Relevé Identité Bancaire (RIB - au format PDF ou image)',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image avec une taille < 5 Mo',condfx:function(val){return (val.size < 5242880 && (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1)); }}
          ],
          fxback:function(){
            tmain.ctrldataform={};
          },
          fxsave:function(ctrl){
            engine.File_Upload(ctrl.file_rib,'rib',function(){
              tmain.ctrldataform={};
              tmain.refresh_ui();
            });          
          }
        };
      }
      if (doctype=="cmuc"){
        tmain.ctrldataform = { 
          title:"Envoyer des pièces justificatives",
          controls:[
            {datatype:'file',databind:'file_cmuc',description:'Attestation CMUC (au format PDF ou image)',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image avec une taille < 5 Mo',condfx:function(val){return (val.size < 5242880 && (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1)); }}
          ],
          fxback:function(){
            tmain.ctrldataform={};
          },
          fxsave:function(ctrl){
            engine.File_Upload(ctrl.file_cmuc,'cmuc',function(){
              tmain.ctrldataform={};
              tmain.refresh_ui();
            });          
          }
        };
      }
      if (doctype=="passmobilite"){
        tmain.ctrldataform = { 
          title:"Envoyer des pièces justificatives",
          controls:[
            {datatype:'html',description:'<p style="border:solid 2px red;padding:5px;">ATTENTION : Pour bénéficier de ce tarif à 24€/mois (264€/an), vous devez être détenteur d\'un <a target="_blank" href="https://www.lepilote.com/fr/tarifs/7/Fare/pass-integral-annuel-aix-marseille-provence/199?PartnerId=10&Frequency=THM_FARE_6">Pass Integral Annuel à 68€/mois</a> ou d\'un <a target="_blank" href="https://www.lecaraixmarseille.com/pass-metropole">Pass Métropole à 30,40€/mois</a>, en cours de validité. Aucun autre abonnement n\'est éligible à ce tarif.<br/><br/>NB : Seule l\'attestation d\'abonnement Pass Intégral ou Pass Métropole sera acceptée, la photo de votre carte de transport n\'a pas valeur de justificatif. Vous pouvez demander une attestation d\'abonnement sur le site RTM <a target="_blank" href="https://www.rtm.fr/attestation-abonnement">https://www.rtm.fr/attestation-abonnement</a>.</p>' },
            {datatype:'file',databind:'file_passmobilite',description:'Attestation Abonnement Intégral Annuel ou Pass Métropole (au format PDF ou image)',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image avec une taille < 5 Mo',condfx:function(val){return (val.size < 5242880 && (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1)); }}
          ],
          fxback:function(){
            tmain.ctrldataform={};
          },
          fxsave:function(ctrl){
            engine.File_Upload(ctrl.file_passmobilite,'passmobilite',function(){
              tmain.ctrldataform={};
              tmain.refresh_ui();
            });          
          }
        };
      }
    },
    account_inactive:function(){
      var tmain = this;
      tmain.ctrldataform = { 
        title:"Désactiver le compte levélo+",
        controls:[
          {datatype:'label',datavalue:null,databind:'',description:'Souhaitez-vous désactiver votre compte levélo+ ? Un email sera envoyé auprès de nos services afin de traiter votre demande.'}
        ],
        fxback:function(){
          tmain.ctrldataform={};
        },
        fxsave:function(){
          engine.Account_Inactive(function(){
            tmain.refresh_ui();
          });
          tmain.ctrldataform={};
        }
      };
    },
    account_delete:function(){
      var tmain = this;
      tmain.ctrldataform = { 
        title:"Supprimer le compte levélo+",
        controls:[
          {datatype:'label',datavalue:null,databind:'',description:'Souhaitez-vous supprimer votre compte levélo+ ? Un email sera envoyé auprès de nos services afin de traiter votre demande.'}
        ],
        fxback:function(){
          tmain.ctrldataform={};
        },
        fxsave:function(){
          engine.Account_Delete(function(){
            tmain.refresh_ui();
          });
          tmain.ctrldataform={};
        }
      };
    },
    navigation:function(app){
      var tmain = this;
      tmain.$router.push('/'+app);
    }
  }
}
</script>
<style>
</style>
